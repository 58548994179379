<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <a href="/">
        <base-img
          :src="require('@/assets/awmtelco-logo.png')"
          class="mr-1"
          contain
          max-width="120"
          width="100%"
        />
      </a>

      <!-- <base-img
        :src="require('@/assets/logo-awm-web.png')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="180"
        width="100%"
      /> -->

      <!-- <base-img
        :src="
          require(`@/assets/zero-logo-${
            $vuetify.theme.isDark ? 'dark' : 'light'
          }.svg`)
        "
        contain
        max-width="96"
        width="100%"
      /> -->

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-btn
        class="font-weight-bold"
        color="#FFBD4A"
        dark
        depressed
        href="mailto:info@awmtelco.com"
        rel="noopener"
        target="_blank"
        x-large
      >
        Get in Touch
      </v-btn>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click=";(drawer = !drawer), test()"
      />
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/about-us">
            <v-list-item-icon>
              <v-icon>mdi-account-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About us</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "HomeAppBar",

  data: () => ({
    drawer: null,
    items: ["Home", "About us"],
  }),
  methods: {
    test() {
      console.log("clicked")
    },
  },
}
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
